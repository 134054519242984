import React, { useEffect, useState, useRef } from 'react';
import _uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';
import { MobXProviderContext, observer } from 'mobx-react';
import { Button, HitBox, Modal, ModalContent, ModalTitle, shadeAlternate, styled } from '@lib/components';
import _sortBy from 'lodash/sortBy';
import { Category, Dish, Menu } from './type';
import { isEmpty } from 'lodash';
import cn from "classnames";

const Btn = styled('div') <{disabled?: boolean}>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${({ theme }) => theme.box.text};
  transition: 0.23s all;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.size}px;

  > .check-circle {
    transition: 0.23s all;
    border: 2px solid ${({ theme }) => shadeAlternate(0.1, theme.box.border)};
    border-radius: 100%;
    width: 16px;
    height: 16px;
    &:after {
      content: ' ';
    }
  }

  &:hover,
  &.active {
    transform: scale(1.015);

    color: ${({ theme }) => theme.colors.primary};
    > .check-circle {
      background: ${({ theme }) => theme.colors.primary};
      width: 16px;
      height: 16px;
    }
  }

  &.disabled {
    transform: scale(1.015);
    color: lightgray;
    > .check-circle {
      background: lightgray;
      width: 16px;
      height: 16px;
    }
  }
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const FreeItemsWrapper = styled.div`
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  @media (min-width: 1200px) {
    max-height: 450px;
  }
`;
const FreeItemsName = styled.p`
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
    &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const getDishes = (matchedTier: T.Schema.Restaurant.FreeItemPromo.ConditionTiers, menus: Menu[]) => {
  let items: Dish[] = [];

  if (matchedTier) {
    for (const property in matchedTier.free_items) {
      let key = matchedTier.free_items[property];

      if (key.menu && key.menu.length > 0) {
        menus.forEach((menu: Menu) => {
          if (key.menu.includes(menu._id)) {
            menu.categories.forEach((category: Category) => {
              items = [...items, ...category.dishes];
            });
          }
        });
      }

      if (key.category && key.category.length > 0) {
        menus.forEach((menu: Menu) => {
          menu.categories.forEach((category: Category) => {
            if (key.category.includes(category._id)) {
              items = [...items, ...category.dishes];
            }
          });
        });
      }

      if (key.dish && key.dish.length > 0) {
        menus.forEach((menu: Menu) => {
          menu.categories.forEach((category: Category) => {
            category.dishes.forEach((dish: Dish) => {
              if (key.dish.includes(dish._id)) {
                items = [...items, dish];
              }
            });
          });
        });
      }
    }
  }
  return [...new Set(items)];
};

function getEligibleDishes(promoEligibleItems: any, restaurant: any) {
  // @ts-ignore
  const dishes: string = Object.values(promoEligibleItems).flatMap((item: any) => item?.dish);
  // @ts-ignore
  const categories: string = Object.values(promoEligibleItems).flatMap((item: any) => item?.category);
  // @ts-ignore
  const menus: string = Object.values(promoEligibleItems).flatMap(item => item?.menu);
  const eligibleDishes = [...dishes];
  if (categories.length > 0) {
    const dishesInEligibleCategories = restaurant.menus.map((menu: Menu) => {
      const selectedCategories = menu.categories.filter((category: Category) => categories.includes(category._id));
      // @ts-ignore
      return selectedCategories.flatMap((category: Category) => category.dishes);
    }).flat();
    eligibleDishes.push(...dishesInEligibleCategories.map((item: Dish) => item._id));
  }

  if (menus.length > 0) {

    const dishesInEligibleMenus = restaurant.menus.filter((menu: Menu) => menus.includes(menu._id))
      // @ts-ignore
      .flatMap((menu: Menu) => menu.categories.flatMap((category: Category) => category.dishes));
    eligibleDishes.push(...dishesInEligibleMenus.map((item: Dish) => item._id));
  }
  return eligibleDishes;
}

function FreeItemsSelection() {
  const { store } = React.useContext(MobXProviderContext);
  const { t } = useTranslation();
  const restaurant = store.restaurant;
  const isMobileFullScreen = restaurant.website.sections?.mobiles?.mobile_fullscreen;
  const selectedCount = useRef<number>(0);
  const [dishes, setDishes] = useState([] as Dish[]);
  const [selectedItems, setSelectedItems] = useState([] as Dish[]);
  const cartItemAmount = store.cart.total;
  const promo = store.cart.s.promos.length > 0 ? store.cart.s.promos[0] : null;
  let conditionType = promo && promo.condition ? promo.condition.type : null;
  let eligibleDishes: string[] = []
  let freeQtyTier: any;
  let freeQty: any;
  const havePromoFreeItem = promo && promo.type === 'free_item';
  const havePromoCodeWithFreeItem = promo && !promo?.type && promo?.free_dishes && !isEmpty(promo?.free_dishes);

  const getDishesForPromoCode = (promo: any, menus: Menu[]) => {
    let items: Dish[] = [];
    if (!promo?.free_dishes || !menus) return items;
    // @ts-ignore
    items = menus.flatMap(menu => menu.categories.flatMap(category => category.dishes))
      .filter((dish: { _id: any; }) => promo.free_dishes.includes(dish._id))
    
    return [...new Set(items)];
  };

  if (havePromoFreeItem) {
    switch (conditionType) {
      case 'item_quantity':
        const promoEligibleItems= promo.condition.eligible_items;
        eligibleDishes = getEligibleDishes(promoEligibleItems, restaurant);
        const eligibleItemsQtyInCart = store.cart.s.items
          .filter((item: any) => eligibleDishes.includes(item._id))
          .reduce((acc: any, item: any) => acc + item.qty, 0);
        freeQtyTier = store.cart.conditionTierFilter(promo, eligibleItemsQtyInCart, 'free_quantity');

        freeQty = freeQtyTier.freeQty;
        break;
      case 'order_amount':
        freeQtyTier = store.cart.conditionTierFilter(promo, cartItemAmount, 'free_quantity');
        freeQty = freeQtyTier.freeQty;
        break;
      default:
        break;
    }
  }
  if (havePromoCodeWithFreeItem) {
    freeQty = promo.free_qty ? promo.free_qty : 1
  }

  const selectMode = Number(freeQty) <= 1 ? 'single' : 'multiple';

  useEffect(() => {
    if (havePromoFreeItem) {
      setDishes(
        getDishes(freeQtyTier.matchedTier, restaurant.menus).map((item: Dish) => {
          item.free_qty = 0;
          return item;
        })
      );
    } else if (havePromoCodeWithFreeItem) {
      setDishes(
        getDishesForPromoCode(promo, restaurant.menus).map((item: Dish) => {
          item.free_qty = 0;
          return item;
        })
      )
    } else {
      setDishes([]);
    }
  }, [
    store.cart.s.promos && store.cart.s.promos.length > 0 ? store.cart.s.promos[0].condition?.free_items : null,
    restaurant?.menus,
    havePromoFreeItem,
    havePromoCodeWithFreeItem
  ]);

  const plus = (item: Dish) => {
    if (selectedCount.current > 100) return;
    if (selectedCount.current === Number(freeQty)) return;
    selectedCount.current += 1;
    let freeNumber = item.free_qty ? item.free_qty : 0
    setDishes(
      dishes.map((dish: Dish) => {
        if (dish._id === item._id) {
          item.free_qty = freeNumber + 1;
        }
        return dish;
      })
    );
    const found = selectedItems.findIndex(dish => dish._id === item._id) !== -1;
    if (!found) {
      selectedItems.push(item);
    }
  };

  const subtract = (item: Dish) => {
    if (item.free_qty === 0 || !item.free_qty) return;
    if (selectedCount.current <= Number(freeQty)) {
      selectedCount.current -= 1;
      let freeNumber = item.free_qty ? item.free_qty : 0
      setDishes(
        dishes.map((dish: Dish) => {
          if (dish._id === item._id) {
            item.free_qty = freeNumber - 1;
          }
          return dish;
        })
      );
    }
    const firstFound = selectedItems.findIndex(each => each._id === item._id);
    if (firstFound > -1) {
      selectedItems.splice(firstFound, 1);
    }
  };

  const customEnter = (item: Dish, newValue: number) => {
    if (newValue < 0 || newValue > Number(freeQty) || selectedCount.current === newValue) return;
    let prevValue = selectedCount.current;
    selectedCount.current = newValue;
    setDishes(
      dishes.map((dish: Dish) => {
        if (dish._id === item._id) {
          prevValue = item.free_qty!;
          item.free_qty = newValue;
        }
        return dish;
      })
    );
    const isAdd = newValue > prevValue;

    if (isAdd) {
      const found = selectedItems.findIndex(dish => dish._id === item._id) !== -1;
      if (!found) {
        selectedItems.push(item);
      }
    } else {
      const firstFound = selectedItems.findIndex(each => each._id === item._id);
      if (firstFound > -1) {
        selectedItems.splice(firstFound, 1);
      }
    }
  };

  const onClose = () => {
    selectedCount.current = 0;
    setDishes(dishes.map((item: Dish) => {
      item.free_qty = 0;
      return item;
    }));
    store.modal.toggle('selectFreeItems');
  };
  return (
    <Modal
      id="selectFreeItems"
      width={420}
      closeButton={true}
      active={store.modal.isVisible('selectFreeItems')}
      close={() => onClose()}
      isFullScreen={isMobileFullScreen}
    >
      <ModalTitle paddinglr={25} paddingtb={20} className="round-top">
        <div>
          <h4 className="font-16">{t('store.modals.free_items_promo.title')}</h4>
          <p className="m-t-2">
            {selectMode === 'multiple'
              ? t('store.modals.free_items_promo.free_items_selection.multiple.description', {
                number: Number(freeQty),
              })
              : t('store.modals.free_items_promo.free_items_selection.single.description', { number: Number(freeQty) })}
          </p>
        </div>
      </ModalTitle>

      <FreeItemsWrapper>
        {selectMode === 'multiple' ? (
          <>
            {dishes.map((item: Dish, index) => (
              <ModalContent paddingtb={5} paddinglr={15} className="flex-l-r-center" key={index}>
                <FreeItemsName>{item.name}</FreeItemsName>
                <HitBox
                  up={() => {
                    if(item.type !== 'combo'){
                      plus(item)
                    }}}
                  down={() => {
                    if(item.type !== 'combo'){
                      subtract(item)
                    }
                  }}
                  value={item.free_qty ? item.free_qty : 0}
                  editable
                  onChange={e => {
                    if(item.type !== 'combo'){
                      const parsed = parseInt(e, 10);
                    let val = 0;
                    if (!isNaN(parsed)) {
                      if (parsed < 0) val = parsed * -1;
                      else if (parsed > 100) val = 100;
                      else val = parsed;
                    }
                    customEnter(item, val);
                    }
                  }}
                />
              </ModalContent>
            ))}
          </>
        ) : (
          <>
            {dishes.map((item: Dish, index) => (
              <ModalContent paddingtb={10} paddinglr={15} className="flex-l-r-center" key={index}>
                <FreeItemsName className={cn("", item.type === "combo" && "disabled")}>{item.name}</FreeItemsName>
                <Btn
                  className={cn("", selectedItems.find(e => e?._id === item._id) && 'active' , item.type === "combo" && "disabled")}
                  onClick={() => {
                    if(item.type !== 'combo'){
                      setSelectedItems([item]);
                    }
                  }}
                  
                >
                  <span className="check-circle" />
                </Btn>
              </ModalContent>
            ))}
          </>
        )}
      </FreeItemsWrapper>

      <ModalContent>
        <Button
          full={true}
          color="primary"
          type="submit"
          onClick={() => {
            const freeOrderDishes = _uniqBy(selectedItems, d => d._id).map((dish: Dish) => {
              const orderDish: T.Schema.Order.OrderDish = {
                ...dish,
                qty: dish.free_qty || 1,
                notes: '',
                price: 0,
                base_price: 0,
                option_sets: [],
                isFreeItems: true,
              };
              return orderDish;
            });
            selectedCount.current = 0;
            setSelectedItems([]);
            store.cart.addFreeItemsToCart(freeOrderDishes);
            localStorage.setItem('isSelectedFreeItem', 'true');
            store.modal.show('cart');
          }}
        >
          {t('store.modals.free_items_promo.free_items_selection.confirm_button')}
        </Button>
      </ModalContent>
    </Modal>
  );
}

export const FreeItemsSelectionModal = observer(FreeItemsSelection);
